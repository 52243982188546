<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl text-gray-200"
      >
        <div class="max-w-2xl w-full p-7">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
      </div>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div>
                  <div class="flex flex-col items-center">
                    <img :src="prompt.img_1" class="" style="width: 50%" />
                  </div>

                  <div class="flex flex-col items-center mt-3">
                    <h3
                      class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
                    >
                      {{ prompt.title }}
                    </h3>
                  </div>

                  <div class="text-gray-200 w-full mt-3">
                    <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                      <div class="">
                        <div class="flex">
                          <h3 class="text-xs font-bold p-2">
                            {{ countWord(prompt.prompt) }} words
                          </h3>

                          <h3 class="text-xs font-bold p-2">
                            Test <i class="fa fa-check-square ml-1"></i>
                          </h3>

                          <h3 class="text-xs font-bold p-2">
                            Tips <i class="fa fa-check-square ml-1"></i>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-gray-200 w-full mt-2">
                    <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                      <div class="col-span-2">
                        <div class="flex">
                          <h3 class="text-sm font-bold p-2">
                            <i class="fas fa-tags"></i>

                            {{ userSeller.sold }}
                          </h3>

                          <div class="">
                            <div
                              class="rounded bg-gradient-to-r from-blue-600 to-green-600 p-1"
                            >
                              <i class="fad fa-wallet mr-1"></i>
                              {{ prompt.address.slice(0, 5) }} ...
                              {{
                                prompt.address.slice(
                                  prompt.address.length - 5,
                                  prompt.address.length - 1
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="">
                        <div class="flex">
                          <h3 class="text-xs font-bold p-2">No reviews yet</h3>
                          <h3 class="text-sm font-bold p-2">
                            <i class="fas fa-eye"></i>

                            {{ prompt.view }}
                          </h3>

                          <h3 class="text-sm font-bold p-2">
                            <i class="fas fa-heart"></i>

                            {{ prompt.favorite }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                  >
                    {{ prompt.description }}
                  </h3>

                  <h3 class="text-2xl font-bold mt-5 text-gray-200">
                    {{ prompt.price }}
                  </h3>

                  <button
                    class="w-42 mt-2 flex-none text-xl text-base px-6 py-2 border rounded border-none bg-gradient-to-r from-green-700 via-green-500 to-green-700 text-gray-200"
                    @click="pay()"
                  >
                    Buy Prompt
                  </button>

                  <h3
                    class="text-xs font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300 mt-2"
                  >
                    After purchasing, you will gain access to the prompt file,
                    which you can use with Midjourney. You must already have
                    access to Midjourney to use this prompt.
                  </h3>

                  <!-- <div class="text-gray-200 mt-10">
                    <div class="flex flex-col">
                      <div class="flex">
                        <div class="text-sm">Balance</div>

                        <div class="flex-grow text-right">
                          <span
                            class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                            ><span>
                              {{
                                parseFloat(
                                  getBalance["USDT"].available
                                ).toFixed(4)
                              }}
                              USDT</span
                            ></span
                          >
                          <div class="mt-3">
                            <button
                              class="flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                              v-on:click="showDeposit = true"
                            >
                              Deposit
                              <i class="fas fa-arrow-circle-down ml-3"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="mb-10" v-if="showDeposit">
                  <div
                    class="flex-grow text-right text-gray-200 text-xs"
                    style="
                      -webkit-text-decoration-line: underline;
                      text-decoration-line: underline;
                    "
                    v-if="lasttx != ''"
                  >
                    <div
                      class="mx-auto max-w-xl flex-col items-center justify-start flex-grow w-full pr-3"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        class="inline w-6 h-6 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                      <a
                        :href="'https://bscscan.com/tx/' + lasttx"
                        target="_blank"
                      >
                        {{ lasttx }}
                      </a>
                    </div>
                  </div>

                  <div
                    class="mx-auto max-w-xl flex-col items-center justify-start flex-grow w-full p-3"
                  >
                    <div
                      class="inline-block w-full mt-1 p-px bg-gradient-to-r from-blue-800 via-gray-500 to-green-700 rounded"
                    >
                      <div
                        class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900 bg-opacity-75"
                      >
                        <div
                          class="flex flex-col sm:flex-row px-8 py-3 sm:py-6"
                        >
                          <div class="flex flex-grow items-center py-3 sm:py-0">
                            <!-- <img class="h-8 mr-2" :src="getCurrency[currency].logo" /> -->
                            <span
                              class="text-2xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-pink-400"
                            >
                              Deposit to Balance</span
                            >
                          </div>

                          <div class="flex-none sm:mt-2">
                            <div
                              class="inline text-xs px-2 py-1 ml-2 border rounded-full text-yellow-50 border-yellow-50 cursor-pointer opacity-80 hover:opacity-100 focus:opacity-100"
                            >
                              Add to
                              <img
                                src="@/assets/svg/metamask.svg"
                                class="inline-block ml-1 h-5"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="px-8 py-3 sm:py-6">
                          <div class="text-gray-200">
                            <div class="flex flex-col">
                              <div>
                                <div class="flex font-semibold mt-2">
                                  <span
                                    class="flex-grow leading-tight text-gray-200 sm:text-base"
                                    style="font-size: 12px"
                                  >
                                    Available in Balance
                                  </span>
                                  <span
                                    class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                                    ><span style="font-size: 14px">
                                      {{
                                        parseFloat(
                                          getBalance["USDT"].available
                                        ).toFixed(4)
                                      }}
                                      USDT</span
                                    ></span
                                  >
                                </div>
                                <div class="flex font-semibold mt-2">
                                  <span
                                    class="flex-grow leading-tight text-gray-200 sm:text-base"
                                    style="font-size: 12px"
                                  >
                                    Available Deposit
                                  </span>
                                  <span
                                    class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                                    ><span style="font-size: 14px">
                                      {{ tokenBalance }} USDT</span
                                    ></span
                                  >
                                </div>

                                <div class="mt-2">
                                  <div
                                    class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                                  >
                                    <input
                                      id="amount"
                                      v-model="deposit_amount"
                                      placeholder="0"
                                      class="flex-grow outline-none text-sm bg-transparent"
                                      type="number"
                                      min="0"
                                      step="0.00000001"
                                    /><span
                                      class="w-20 flex-none text-sm px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                                    >
                                      MAX
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="mx-auto max-w-xl flex-col items-center justify-start flex-grow w-full p-3"
                  >
                    <button
                      class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                      v-if="loading"
                      disabled
                    >
                      <div class="">
                        <svg
                          aria-hidden="true"
                          role="status"
                          class="inline w-6 h-6 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          />
                        </svg>
                        Processing
                      </div>
                    </button>
                    <button
                      class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                      disabled
                      v-else-if="!deposit_amount"
                    >
                      Deposit
                    </button>
                    <button
                      class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                      v-else
                      v-on:click="deposit()"
                    >
                      Deposit
                    </button>
                  </div>

                  <div class="flex flex-col items-center mt-4">
                    <depositPage v-if="lasttx2 != ''" lasttx="lasttx" />
                    <depositPage v-else lasttx="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import depositPage from "./deposit.vue";

import { mapGetters, mapActions } from "vuex";

import Web3 from "web3";
import nativeToast from "native-toast";
import Swal from "sweetalert2";

import Prompt from "@/api/prompt";

import { POOL_ADDRESS, VERSION } from "../../config";

import { defineComponent } from "vue";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

const tokenABI = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],

    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      _id: this.$route.params._id,
      prompt: {},
      promptAllByAddress: [],
      tokenBalance: 0,
      contract_address: "0x55d398326f99059fF775485246999027B3197955",
      loading: false,
      deposit_amount: null,
      showDeposit: true,
      lasttx: "",
      lasttx2: "",
      userSeller: {},
    };
  },
  components: {
    ConnectWallet,

    Carousel,
    Slide,
    Pagination,
    Navigation,
    depositPage,
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    getPrompt() {
      Prompt.byId(this._id)
        .then((res) => {
          this.prompt = res.data.prompt;

          this.userSeller = res.data.userSeller;

          this.promptAllByAddress = res.data.promptAllByAddress;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getTokenBalance() {
      const web3 = new Web3(ethereum);

      const tokenInst = new web3.eth.Contract(tokenABI, this.contract_address);
      const token_raw = await tokenInst.methods
        .balanceOf(this.accounts[0])
        .call();

      this.tokenBalance = (token_raw / Math.pow(10, 18)).toFixed(4);
    },
    async getAccount() {
      this.accounts = await ethereum.request({ method: "eth_requestAccounts" });
      this.getTokenBalance();
    },

    deposit() {
      this.loading = true;
      if (this.tokenBalance / 1 < this.deposit_amount / 1) {
        this.loading = false;
        nativeToast({
          message: `The amount you entered is more than your available balance.( You have ${this.tokenBalance} ${this.currency})`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
        return;
      }
      const web3 = new Web3(ethereum);
      var contract = new web3.eth.Contract(tokenABI, this.contract_address);
      this.to = this.contract_address; //contract address
      this.value = "0x0";
      this.data = contract.methods
        .transfer(
          POOL_ADDRESS,
          (this.deposit_amount * 1000000000000000000).toLocaleString(
            "fullwide",
            { useGrouping: false }
          )
        )
        .encodeABI();
      //Sending Ethereum to an address
      ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: this.accounts[0],
              to: this.to,
              value: this.value,
              data: this.data,
            },
          ],
        })
        .then((txHash) => {
          this.loading = true;
          this.lasttx = txHash;
          this.deposit_amount = null;

          setTimeout(() => {
            this.loading = false;
            this.lasttx = "";

            this.lasttx2 = "xxxxxxxxx";

            setTimeout(() => {
              this.lasttx2 = "xxxxxxxxx";
            }, 7000);

            setTimeout(() => {
              this.lasttx2 = "";
            }, 9000);
          }, 15000);

          this.getTokenBalance();

          setTimeout(() => {
            this.getTokenBalance();
          }, 3500);
          nativeToast({
            message: `Tx Submitted: ${txHash.slice(0, 12)}...${txHash.slice(
              -10
            )}`,
            position: "top",
            timeout: 5000,
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          nativeToast({
            message: error.message,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
    pay() {
      if (
        parseFloat(this.getBalance["USDT"].available) <
        parseFloat(this.prompt.price.slice(2))
      ) {
        nativeToast({
          message: `The have balance ${this.getBalance["USDT"].available} USDT, not enough to pay`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Buy!",
        }).then((result) => {
          if (result.isConfirmed) {
            Prompt.submit({
              prompt_id: this._id,
              address: this.getUser.address,
            })
              .then((res) => {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setTimeout(() => {
                  this.$router.push("/funds");
                }, 2000);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    countWord(myText) {
      if (myText) {
        myText = myText.trim();

        let wordsArray = myText.split(" ");

        let wordCount = wordsArray.length;

        return wordCount;
      }
    },
  },
  created() {
    this.getPrompt();

    this.getAccount();
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>
