<template>
  <div class="z-0">
    <header v-if="showBack">
      <ConnectWallet />
    </header>
    <div
      class="flex flex-col items-center justify-start w-full text-yellow-50 p-3 mb-28"
    >
      <div class="py-1 max-w-5xl w-full flex-none" v-if="showBack">
        <div class="p-4">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
      </div>

      <div class="bg-gray-800 py-2 px-3 rounded max-w-5xl w-full flex-none">
        <div class="text-base font-medium">
          <span class="mr-4 font-bold">Deposit History</span>
          <i class="fad fa-wallet mr-2 z-0"></i>
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-400"
          >
            {{ address.slice(0, 10) }}...{{ address.slice(-8) }}
          </span>
        </div>
      </div>

      <div class="flex-glow max-w-5xl w-full">
        <div v-if="!ready" class="flex flex-col">
          <loader class="h-40"></loader>
          <p class="text-center">Loading...</p>
        </div>
        <div v-else>
          <div v-if="table_rows.length == 0">
            <div class="flex flex-col">
              <chameleon class="h-40"></chameleon>
              <p class="text-center">You don't have a deposit</p>
            </div>
          </div>
          <div v-else class="w-full items-center">
            <div
              v-for="t in table_rows"
              v-bind:key="t.id"
              class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
            >
              <div class="flex-grow flex flex-row">
                <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                  <a
                    class="text-baseline text-blue text-blue-400 mr-4"
                    v-bind:href="`https://${explorer}/tx/${t.tx_id}`"
                    target="_blank"
                    ><img
                      class="inline-block h-5"
                      :src="require(`@/assets/chain/bscscan.png`)"
                    />
                    {{ t.tx_id.slice(0, 6) }}...{{ t.tx_id.slice(-8) }}
                  </a>
                  <div class="r-4 text-gray-500 text-sm sm:inline-block">
                    {{ t.createdAt }}
                  </div>
                </div>
                <div class="text-sm sm:text-base hidden md:block">
                  <span class="text-xs"
                    >chain:
                    <img
                      class="inline-block h-3 ml-2"
                      :src="require(`@/assets/chain/${t.chain}.png`)"
                  /></span>
                </div>
              </div>
              <div
                class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
              >
                <div>
                  {{ t.amount.toFixed(8) / 1 }} {{ t.currency }}
                  <img
                    class="inline-block h-4 ml-2"
                    :src="`/tokens/${t.currency}.png`"
                  />
                  <div
                    v-bind:class="
                      'text-xs sm:inline-block text-center font-semibold mt-1 py-1 rounded uppercase ml-10 sm:ml-2 w-24 ' +
                      t.label
                    "
                  >
                    {{ t.status }}
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center">
              <div
                class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 hover:bg-gray-500 hover:bg-opacity-25 border-none cursor-pointer"
                v-on:click="getdata()"
                v-if="!nomore"
              >
                Load more...
              </div>
              <div
                class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 border-none"
                v-else
              >
                - No more -
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <footer>
        <StickFooter />
      </footer> -->
    </div>
  </div>
</template>

<script>
import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import user from "@/api/user";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";
import { EXPLORER } from "../../config";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    loader,
    chameleon,
    ConnectWallet,
    StickFooter,
  },
  props: ["lasttx2"],
  data() {
    return {
      address: this.$route.params.address || null,
      ready: false,
      page: 0,
      nomore: false,
      table_rows: [],
      expanded: null,
      explorer: EXPLORER,
      showBack: true,
      lasttx2: this.lasttx2,
    };
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

  methods: {
    fixed(a) {
      return func.fixed8(a);
    },
    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    getdata() {
      user
        .deposithistory({ page: this.page, address: this.address })
        .then((res) => {
          this.ready = true;
          if (res.data.length == 0) {
            this.nomore = true;
          } else {
            res.data.forEach((e) => {
              e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

              var a = label.type(e.status);
              e.label = a.label;

              this.table_rows.push(e);
            });
            this.page = this.page + 1;
          }
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    if (this.getUser) {
      this.showBack = false;

      this.address = this.getUser.address;
    }

    if (this.$route.params.address) {
      this.showBack = true;
    }
    this.getdata();
  },
  updated() {
    if (this.lasttx != "") {
      this.getdata();
    }
  },
};
</script>
